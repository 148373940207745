import { defaultNetworkRequest } from "../models/network-request-operation"
import { defaultOperation, Operation } from "../models/operation"
import { addOperation } from "./document-service"
import { v4 as uuidv4 } from 'uuid';
import { message } from 'antd';
import { cloneDeep } from "lodash";


export function createOperation(key: string, defaults: Operation = defaultOperation) {
    let operation: Operation | undefined = defaultOperationForKey(key, defaults)
    if (operation) {
        operation.uuid = uuidv4()
        addOperation(operation)
    } else {
        console.error("Failed to find operation with key ", key)
    }
}

function defaultOperationForKey(key: string, defaults: Operation): Operation | undefined {
    let operation: Operation | undefined
    console.log("key", key)
    switch (key) {
        case 'NetworkRequest':
            operation = cloneDeep(defaultNetworkRequest)
            break;

        default:
            message.info('Coming Soon.');
            return undefined
    }

    return { ...defaults, ...operation }
}