import { Component } from "react";
import './CanvasDrawer.css';
import { defaultDocument, writeDocument } from '../services/document-service'
import { Button, Drawer, Popconfirm, Collapse, Input } from 'antd'
import { LeftOutlined, ClearOutlined, ApiOutlined, LoginOutlined, DatabaseOutlined, TableOutlined, SearchOutlined } from '@ant-design/icons';
import { createOperation } from "../services/operation-factory";


type Props = {
    visible: boolean
    onClose: () => void
}

export class CanvasDrawer extends Component<Props> {

    reset() {
        writeDocument(defaultDocument)
    }

    render() {
        return (
            <Drawer
                title="Add Operation"
                closeIcon={< LeftOutlined />}
                placement="left"
                onClose={this.props.onClose}
                visible={this.props.visible}
                getContainer={false}
                mask={false}
                width={250}
                style={{ position: 'absolute' }}
            >
                <Input className="drawer-search" placeholder="Search..." prefix={<SearchOutlined />} />

                <Collapse defaultActiveKey={['1']} ghost>
                    <Collapse.Panel header="Network" key="1">
                        <Button type="text" className="drawer-button" icon={<ApiOutlined />} onClick={() => createOperation("NetworkRequest")}>
                            Network Request
                        </Button>
                        <Button type="text" className="drawer-button" icon={<LoginOutlined />} onClick={() => createOperation("Webhook")}>
                            Webhook
                        </Button>
                    </Collapse.Panel>
                    <Collapse.Panel header="Data Stores" key="2">
                        <Button type="text" className="drawer-button" icon={<DatabaseOutlined />} onClick={() => createOperation("Webhook")}>
                            Database
                        </Button>
                        <Button type="text" className="drawer-button" icon={<TableOutlined />} onClick={() => createOperation("Webhook")}>
                            Google Sheets
                        </Button>
                    </Collapse.Panel>
                    <Collapse.Panel header="Third-Party" key="3">
                    </Collapse.Panel>
                </Collapse>

                <div className="drawer-footer">
                    <Popconfirm placement="top" title="Are you sure you would like to reset the canvas?" onConfirm={this.reset} okText="Yes" cancelText="Cancel">
                        <Button danger type="primary" icon={<ClearOutlined />}>Reset</Button>
                    </Popconfirm>
                </div>
            </Drawer >
        )
    }
}