

import { Component } from "react";
import './ConnectionCreator.css';
import { Select } from 'antd'
import { Operation } from "../models/operation";
import { Connection } from "../models/connection";
import { connect, ConnectedProps } from 'react-redux'
import { store, StoreState } from "../services/store";
import { Document } from "../models/document";
import { v4 as uuidv4 } from 'uuid';
import { addConnection, removeConnection } from "../services/document-service";
import { cloneDeep } from "lodash";


const mapStateToProps = (state: StoreState) => state
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
    from_operation: Operation
}

type State = {
    connection?: Connection
}

class ConnectionCreator extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.selectConnection = this.selectConnection.bind(this)
    }

    componentDidMount() {
        this.getConnectorForOperation()
    }

    getConnectorForOperation() {
        const state: StoreState = store.getState()
        const document: Document = cloneDeep(state.document)
        const connections: Record<string, Connection> | undefined = document.connections
        if (connections) {
            const connection_uuids: string[] = Object.keys(connections).filter((uuid) => {
                const connection: Connection = connections[uuid]
                return connection.from_uuid === this.props.from_operation.uuid
            })
            if (connection_uuids.length) {
                const conneciton_uuid = connection_uuids[0]
                const connection: Connection = connections[conneciton_uuid]
                console.log("From :", this.props.from_operation, "\nConnection:", connection)
                this.setState(() => { return { connection } })
            }
        }
    }

    selectConnection(to_uuid: string) {
        if (to_uuid.length) {
            let connection: Connection | undefined = this.state?.connection
            if (!connection) {
                connection = {
                    uuid: uuidv4(),
                    to_uuid,
                    from_uuid: this.props.from_operation.uuid
                }
            } else {
                connection.to_uuid = to_uuid
            }
            addConnection(connection)
            this.setState(() => { return { connection } })
        } else if (this.state?.connection) {
            removeConnection(this.state?.connection.uuid)
            this.setState(() => { return { connection: undefined } })
        }
    }

    render() {
        return (
            <div className="send-to-flex">
                <span>Send data to: &nbsp;</span>
                <Select
                    defaultValue={this.state?.connection?.to_uuid}
                    value={this.state?.connection?.to_uuid}
                    className="send-to-dropdown"
                    onSelect={this.selectConnection}
                >
                    <Select.Option value={""}>-</Select.Option>
                    {
                        this.props?.operations &&
                        Object.keys(this.props?.operations).map((uuid: string) => {
                            let jsx
                            if (uuid !== this.props.from_operation.uuid) {
                                const operation: Operation = this.props.operations[uuid]
                                jsx = (<Select.Option key={uuid} value={uuid}>{operation.name}</Select.Option>)
                            }
                            return jsx
                        })
                    }
                </Select>
            </div >
        )
    }
}

export default connector(ConnectionCreator)

