
export interface Operation {
    uuid: string,
    name: string,
    x: Number,
    y: Number,
    width: Number,
    height: Number,
    type: string,
    config: {}
}


export const defaultOperation:Operation ={
    uuid: "",
    name: "Network Request",
    x: 100,
    y: 100,
    width: 400,
    height: 600,
    type: "undefined",
    config: {}
}
