import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import DocumentComponent from './components/DocumentComponent';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './services/store'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DocumentComponent />
    </Provider>
  </React.StrictMode>
);
// const document_uuid = "1014969b-7554-41cd-942b-95a5890ed9f7" // TODO: make dynamic
// getDocument(document_uuid)


console.log("Initail Redux State", store.getState())
store.subscribe(()=>{
  console.log("Updated Redux State", store.getState())
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
