import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// You can add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbRN2pnQfIKx9_gH918radSJstnhnLNtw",
  authDomain: "cinchclick-staging.firebaseapp.com",
  databaseURL: "https://cinchclick-staging-default-rtdb.firebaseio.com",
  projectId: "cinchclick-staging",
  storageBucket: "cinchclick-staging.appspot.com",
  messagingSenderId: "781373326932",
  appId: "1:781373326932:web:13204b92522221f2270fc1",
  measurementId: "G-3W71BZR17K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getDatabase(app);


