import { useRef, useState, useMemo } from "react";
import './Canvas.css';
import { connect, ConnectedProps } from 'react-redux'
import { Operation } from "../models/operation"
import Draggable, { ControlPosition, DraggableData, DraggableEvent } from 'react-draggable'; // Both at the same time
import { writeOperation } from '../services/operation-service'
import OperationWindow from "./Operations/OperationWindow";
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { StoreState } from "../services/store";
import { cloneDeep, debounce } from 'lodash'
// import { cloneDeep, debounce, throttle } from 'lodash'
import { CanvasDrawer } from "./CanvasDrawer";
import Xarrow, { useXarrow } from "react-xarrows";
import { Connection } from "../models/connection";

const mapStateToProps = (state: StoreState) => state
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

const Canvas = (props: Props) => {

    const [visible, setVisible] = useState(false);

    const updateWindowLocation = (operation: Operation, e: DraggableEvent, data: DraggableData) => {
        let newOperation = { ...operation }
        newOperation.x = data.x
        newOperation.y = data.y
        writeOperation(newOperation)
    }

    //TODO: Figure out if its possible to use debounce value higher than single digits
    const debouncedEventHandler = useMemo(() => debounce(updateWindowLocation, 2), []);
    // const throttledEventHandler = useMemo(() => throttle(updateWindowLocation, 300), []);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const nodeRef = useRef(null); //prevents Draggable Error
    const updateXarrow = useXarrow();

    return (
        <div onLoad={updateXarrow} className="canvas">
            <Button className="operation-button" type="primary" icon={<PlusOutlined />} onClick={showDrawer}>
                Add Operation
            </Button>
            <CanvasDrawer visible={visible} onClose={onClose} />

            {
                props?.operations &&
                    Object.keys(props.operations)?.map((uuid: string) => {
                        let operation: Operation = props.operations[uuid]
                        let position: ControlPosition = { x: +operation.x, y: +operation.y }
                        return (
                            <Draggable
                                key={uuid}
                                nodeRef={nodeRef}
                                position={position}
                                onDrag={(e: DraggableEvent, data: DraggableData) => debouncedEventHandler(props.operations[uuid], e, data)}
                            // onStop={(e: DraggableEvent, data: DraggableData) => debouncedEventHandler(props.operations[uuid], e, data)}
                            >
                                <div ref={nodeRef} style={{ width: operation.width + 'px' }} id={uuid}>
                                    {window ? <OperationWindow operation={operation}></OperationWindow> : null}
                                </div>
                            </Draggable>
                        )
                    })
            }
            {
                //TODO: Figure out why doesn't render on first load
                props.document.connections &&
                Object.keys(props.document.connections).map((uuid: string) => {
                    let connections: Record<string, Connection> = props.document.connections as Record<string, Connection>
                    let connection: Connection = cloneDeep(connections[uuid])
                    console.log("rendering connection", connection)
                    return (
                        <Xarrow
                            key={connection.uuid}
                            start={connection.from_uuid} //can be react ref
                            end={connection.to_uuid} //or an id
                        />
                    )
                })
            }
        </div>
    )
}

export default connector(Canvas)
