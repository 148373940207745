import { ChangeEvent, Component } from "react";
import './OperationWindow.css';
import { connect, ConnectedProps } from 'react-redux'
import { Operation } from "../../models/operation"
import { Document } from "../../models/document"
import NetworkRequestOperationWindow from "./NetworkRequestOperationWindow"
import NetworkRequestOperation from "../../models/network-request-operation";
import { Button, Input, Popconfirm } from 'antd'
import { CloseOutlined } from '@ant-design/icons';
import { deleteOperation, writeOperation } from '../../services/operation-service'
import { writeDocument } from '../../services/document-service'
import { StoreState } from "../../services/store";
import ConnectionCreator from "../ConnectionCreator";

const mapStateToProps = (state: StoreState) => state
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
    operation: Operation
}
export class OperationWindow extends Component<Props> {
    // For providing behavior all operations might need, like resizability

    constructor(props: Props) {
        super(props);
        this.removeOperation = this.removeOperation.bind(this)
        this.updateName = this.updateName.bind(this)
    }

    removeOperation() {
        let operation: Operation = { ...this.props.operation }
        let document: Document = { ...this.props.document }
        let uuids = document.operation_uuids.filter((uuid: string) => {
            return uuid !== operation.uuid
        })
        document.operation_uuids = uuids
        writeDocument(document)
        deleteOperation(operation.uuid)
    }

    updateName(changeEvent: ChangeEvent<HTMLInputElement>) {
        let operation: Operation = { ...this.props.operation }
        console.log("updating operation", operation.uuid)
        operation.name = changeEvent.target.value
        writeOperation(operation)
    }

    render() {
        let component
        switch (this.props?.operation?.type) {
            case "NetworkRequest":
                component = <NetworkRequestOperationWindow operation={this.props.operation as NetworkRequestOperation}></NetworkRequestOperationWindow>
                break;
            default:
                component = <div>I can now be moved around!</div>
        }
        return (<div className="operation_window">
            <div className="operation-title">
                <Input
                    className="operation-title-text"
                    size="large" 
                    placeholder="Operation name..."
                    defaultValue={this.props?.operation?.name}
                    value={this.props?.operation?.name}
                    onChange={this.updateName} />
                <Popconfirm placement="bottom" title="Are you sure you would like to delete this operation?" onConfirm={this.removeOperation} okText="Yes" cancelText="Cancel">
                    <Button danger type="text" icon={<CloseOutlined />}></Button>
                </Popconfirm>

            </div>
            {component}
            <ConnectionCreator from_operation={this.props.operation} />
        </div>)
    }

}

export default connector(OperationWindow);