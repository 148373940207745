import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Operation } from "../models/operation"


const initialState: Record<string, Operation> = {}

const slice = createSlice({
    name: 'operation',
    initialState,
    reducers: {
        setOperation(state, { payload }: PayloadAction<Operation>) {
            let newState = {...state}
            if(payload){
                newState[payload.uuid] = payload
            }
            return newState
        },
        unsetOperation(state, { payload }: PayloadAction<string>) {
            let newState:Record<string, Operation> = {...state}
            delete newState[payload]
            return newState
        },
    },
})

export const { setOperation, unsetOperation } = slice.actions;
export default slice.reducer;
