import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Document } from '../models/document'

const initialState = {} as unknown as Document

const slice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocument(state, { payload }: PayloadAction<Document>) { 
            return payload 
        },
    },
})

export const { setDocument } = slice.actions;
export default slice.reducer;
