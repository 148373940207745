import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast } from '@fortawesome/free-solid-svg-icons';
// import { faHome, faPodcast, faQuestion, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import './Header.css';
import { Document } from '../models/document';

interface Props {
  // children?: ReactNode
  document?: Document
}
export const Header = (props: Props) => {
  return (
    <div className="top-header">
      <div className="header-left">
        <div className='cursor-pointer'>
          {/* <img className="logo" src="/assets/icons/einblick-logo-dark.svg"></img> */}
          <span className="title">Cinch</span>
        </div>
        {/* <div className="separator"></div> */}
        <div className="return-to-workspace cursor-pointer">
          {/* <FontAwesomeIcon icon={faHome} /> */}
        </div>
      </div>
      <div className="title-container">
        <div className="file-name-view ">
          <div className="file-name-view__name cursor-pointer">
            {props.document ? props.document.name : "Untitled"}
          </div>
        </div>
      </div>
      <div className="header-right">
        <div className="collaborator-list" touch-action="none">
        </div>
        <div className="stream-settings">
          <div className="live-button cursor-pointer">
            <FontAwesomeIcon icon={faPodcast} />
            <span>Go live</span>
          </div>
        </div>
        {/* <div className="share-button has-fa-icon cursor-pointer">
          <FontAwesomeIcon icon={faShareSquare} />
          <span>Share</span>
        </div> */}
        <div className="header-right-small">
          <div className='cursor-pointer'>
            <div className="user-avatar ">
              <div className="user-avatar__photo-container ">
                <div className="user-avatar__photo">k
                </div>
              </div>
            </div>
          </div>
          {/* <div className="separator">
          </div> */}
          <div className="user-guide cursor-pointer">
            {/* <FontAwesomeIcon icon={faQuestion} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
