import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import DocumentReducer from "./document-reducer"
import OperationReducer from "./operation-reducer"
import { Document } from "../models/document"
import { Operation } from "../models/operation"


export interface StoreState {
    document: Document,
    operations: Record<string, Operation>
}

export const createStore = (
    options?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
    configureStore({
        reducer: {
          document: DocumentReducer,
          operations:OperationReducer
        },
        ...options,
    })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
