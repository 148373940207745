import { ChangeEvent, Component } from "react";
import './NetworkRequestOperationWindow.css';
import { connect, ConnectedProps } from 'react-redux'
import { NetworkRequestOperation, NetworkRequestConfig, NetworkRequestHeader } from "../../models/network-request-operation"
import { Select, Input, Button, Checkbox, Spin } from 'antd'
import { writeOperation } from '../../services/operation-service'
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { v4 as uuidv4 } from 'uuid';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import Prism from 'prismjs';
import { StoreState } from "../../services/store";
import { cloneDeep } from 'lodash'
import { Connection } from "../../models/connection";
import { Document } from "../../models/document";
import { Operation } from "../../models/operation";


const mapStateToProps = (state: StoreState) => state
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {
    // children?: ReactNode
    operation: NetworkRequestOperation
}
type State = {
    // children?: ReactNode
    loading: boolean
}
class NetworkRequestOperationWindow extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.selectMethod = this.selectMethod.bind(this)
        this.updateURL = this.updateURL.bind(this)
        this.addHeader = this.addHeader.bind(this)
        this.toggleHeaderEnabledDisabled = this.toggleHeaderEnabledDisabled.bind(this)
        this.updateHeaderKey = this.updateHeaderKey.bind(this)
        this.updateHeaderValue = this.updateHeaderValue.bind(this)
        this.updateHeaderDescription = this.updateHeaderDescription.bind(this)
        this.updateBody = this.updateBody.bind(this)
        this.removeHeader = this.removeHeader.bind(this)
        this.triggerRequest = this.triggerRequest.bind(this)
    }

    componentDidMount() {
        this.setState(() => { return { loading: false } })
    }

    selectMethod(method: string) {
        const operation = cloneDeep(this.props.operation)
        operation.config.method = method
        writeOperation(operation)
    }

    updateURL(changeEvent: ChangeEvent<HTMLInputElement>) {
        const url: string = changeEvent.target.value
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        operation.config.url = url
        writeOperation(operation)
    }

    addHeader() {
        const uuid: string = uuidv4();
        const defaultHeader: NetworkRequestHeader = { uuid, enabled: true, key: "key" }
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        if (!operation.config.headers) {
            operation.config.headers = {}
        }
        operation.config.headers[uuid] = defaultHeader
        writeOperation(operation)
    }

    removeHeader(header_uuid: string) {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        let headers = operation.config.headers
        if (headers) {
            delete (headers as Record<string, NetworkRequestHeader>)[header_uuid]
            operation.config.headers = headers
            writeOperation(operation)
        }
    }

    toggleHeaderEnabledDisabled(header_uuid: string, e: CheckboxChangeEvent) {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        let header = operation.config.headers[header_uuid]
        header.enabled = e.target.checked
        writeOperation(operation)
    };

    updateHeaderKey(header_uuid: string, changeEvent: ChangeEvent<HTMLInputElement>) {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        let header = operation.config.headers[header_uuid]
        header.key = changeEvent.target.value
        writeOperation(operation)
    }

    updateHeaderValue(header_uuid: string, changeEvent: ChangeEvent<HTMLInputElement>) {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        let header = operation.config.headers[header_uuid]
        header.value = changeEvent.target.value
        writeOperation(operation)
    }

    updateHeaderDescription(header_uuid: string, changeEvent: ChangeEvent<HTMLInputElement>) {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        let header = operation.config.headers[header_uuid]
        header.description = changeEvent.target.value
        writeOperation(operation)
    }

    updateBody(changeEvent: ChangeEvent<HTMLTextAreaElement>) {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        operation.config.body = changeEvent.target.value
        writeOperation(operation)
    }

    async triggerRequest() {
        const operation: NetworkRequestOperation = cloneDeep(this.props.operation)
        const config: NetworkRequestConfig = operation.config
        const url: string = "https://bfa-cors-anywhere.herokuapp.com/" + config.url as string
        const request: any = { "method": config.method }
        if (config.headers) {
            const headers = Object.keys(config.headers).reduce((acc: Record<string, string>, uuid: string) => {
                const header: NetworkRequestHeader = (config.headers as Record<string, NetworkRequestHeader>)[uuid]
                if (header && header.key && header.value) {
                    acc[header.key] = header.value as string
                }
                return acc
            }, {})
            request.headers = headers
        }
        let body = config.body
        if (config.method === "POST" && config.body) {
            const x = config.body.replace(/[^A-Za-z0-9.$]+/g, " ");
            const substitutions = x.trim().split(" ").filter((word: string) => { return word.startsWith("$input.") });
            console.log('substitutions', substitutions)
            if (substitutions.length) {
                const previousOperation: Operation | undefined = this.previousOperation()
                console.log('previousOperation', previousOperation)
                if (previousOperation) {
                    const previous: NetworkRequestOperation = previousOperation as NetworkRequestOperation
                    substitutions.forEach(sub => {
                        let drilldown: Record<string, any> = cloneDeep(previous.response)
                        sub.split(".").forEach((path: string) => {
                            if (path !== "$input") {
                                if (Object.keys(drilldown).indexOf(path) !== -1) {
                                    drilldown = drilldown[path]
                                } else {
                                    console.warn("drilldown missing path", path, drilldown)
                                }
                            }
                        })
                        console.log("replacing", sub)
                        body = body.replace(sub, JSON.stringify(drilldown))
                    });
                }
            }
        }

        console.log("config.body", body)
        request.body = body
        this.setState(() => { return { loading: true } })

        const response = await fetch(url, request)
        operation.response = await response.json();
        console.log("Got network response", operation.response)
        writeOperation(operation)
        this.setState(() => { return { loading: false } })

    }

    previousOperation(): Operation | undefined {
        if (this.props.document !== undefined) {
            const document: Document = this.props.document
            if (document.connections !== undefined) {
                const connections: Record<string, Connection> = document.connections
                const linkedConnections: Connection[] = Object.keys(connections).reduce((acc: Connection[], connection_uuid: string) => {
                    const connection = connections[connection_uuid]
                    if (connection.to_uuid === this.props.operation.uuid) {
                        acc.push(connection)
                    }
                    return acc
                }, [] as Connection[])

                if (linkedConnections.length) {
                    const connection = linkedConnections[0]
                    return this.props.operations[connection.from_uuid]
                } else {
                    console.warn("No Inputs!")
                }
            }
        }
        return undefined

    }

    render() {
        let config = this.props?.operation?.config
        let headers: Record<string, NetworkRequestHeader> | undefined = config?.headers;

        const selectBefore = (
            <Select
                defaultValue={config.method}
                value={config.method}
                className="select-before"
                onSelect={this.selectMethod}
            >
                <Select.Option value="GET">GET</Select.Option>
                <Select.Option value="POST">POST</Select.Option>
            </Select>
        );

        let code = undefined
        if (this.props.operation.response) {
            code = Prism.highlight(JSON.stringify(this.props.operation.response, null, 2), Prism.languages.javascript, 'javascript')
        }

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        return (
            <div className="network-request-operation">

                <Input
                    addonBefore={selectBefore}
                    defaultValue={config.url || "http://"}
                    value={config.url}
                    onChange={this.updateURL}
                />
                <div className="network-request-add-header-flex">
                    <p>Headers</p>
                    <Button onClick={this.addHeader}>New Header</Button>
                </div>
                {
                    headers &&
                    Object.keys(headers)?.map((uuid: string) => {
                        let header: NetworkRequestHeader = (headers as Record<string, NetworkRequestHeader>)[uuid]
                        return (
                            <div key={uuid} className="network-request-header-row-flex">
                                <Checkbox checked={header.enabled} onChange={(e: CheckboxChangeEvent) => this.toggleHeaderEnabledDisabled(header.uuid, e)}></Checkbox>
                                <Input
                                    disabled={!header.enabled}
                                    placeholder="key"
                                    defaultValue={header.key}
                                    value={header.key}
                                    onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => this.updateHeaderKey(header.uuid, changeEvent)}
                                />
                                <Input
                                    disabled={!header.enabled}
                                    placeholder="value"
                                    defaultValue={header.value}
                                    value={header.value}
                                    onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => this.updateHeaderValue(header.uuid, changeEvent)}
                                />
                                <Input
                                    disabled={!header.enabled}
                                    placeholder="description"
                                    defaultValue={header.description}
                                    value={header.description}
                                    onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => this.updateHeaderDescription(header.uuid, changeEvent)}
                                />
                                <Button danger type="text" icon={<CloseOutlined />} onClick={() => this.removeHeader(header.uuid)}></Button>
                            </div>
                        )

                    })
                }

                {
                    (config as NetworkRequestConfig)?.method === "POST" &&
                    (
                        <div>
                            <div className="network-request-body-flex">
                                <span>Body</span>
                                {/* <Button onClick={this.addHeader}>New Header</Button> */}
                            </div>
                            {/* TODO: Allow pulling in variable values.  */}
                            <Input.TextArea
                                placeholder="{json}"
                                defaultValue={config.body}
                                value={config.body}
                                onChange={(changeEvent: ChangeEvent<HTMLTextAreaElement>) => this.updateBody(changeEvent)}
                            />
                        </div>
                    )
                }

                {
                    (
                        <div>
                            <div className="network-request-response-flex">
                                <span>Response</span>
                                <Button onClick={this.triggerRequest}>Trigger Manually</Button>
                            </div>
                            {!this.state?.loading &&
                                <pre className="js-code"
                                    dangerouslySetInnerHTML={{
                                        __html: code ? code as string : "Not Run"
                                    }}>
                                </pre>
                            }
                            {this.state?.loading &&
                                <div className="loading-spinner">
                                    <Spin indicator={antIcon} />
                                </div>
                            }
                        </div>
                    )
                }
            </div>
        )
    }

}

export default connector(NetworkRequestOperationWindow)