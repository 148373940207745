import { set, ref, onValue, DataSnapshot, remove } from "firebase/database";
import { db } from "./firebase"
import { Operation } from "../models/operation"
import { store } from "./store";
import { setOperation } from "./operation-reducer";

export type OperationCallback = (operation: Operation) => void
export let operation: Operation

function operationRef(uuid: string) {
    return ref(db, 'operations/' + uuid)
}

export function writeOperation(operation: Operation, completionCallback?: OperationCallback) {
    set(operationRef(operation.uuid), operation);
    // store.dispatch(setOperation(operation))
}

export function getOperation(uuid: string, callback: OperationCallback) {
    return onValue(operationRef(uuid), (snapshot: DataSnapshot) => {
        operation = snapshot.val();
        if(operation){
            store.dispatch(setOperation(operation))
            callback(operation)
        } else {
            console.log("Operation null", operation)
        }
    });
}

export function deleteOperation(uuid: string) {
    remove(operationRef(uuid))
}
