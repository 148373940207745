import { Component, Fragment } from "react";
import { connect, ConnectedProps } from 'react-redux'
import './DocumentComponent.css';
import { getDocument } from "../services/document-service";
import { Header } from './Header'
import Canvas from "./Canvas";
import { StoreState } from "../services/store";


const mapStateToProps = (state: StoreState) => state
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = PropsFromRedux & {}

let loaded = false

class DocumentComponent extends Component<Props> {

    componentDidMount() {
        if (this.props.document !== undefined) {
            if (!loaded) {
                const document_uuid = "1014969b-7554-41cd-942b-95a5890ed9f7" // TODO: make dynamic
                getDocument(document_uuid)
                loaded = true;
            }
        }
    }

    render() {
        return (
            <div className="DocumentComponent">
                <Fragment>
                    <Header />
                    <Canvas />
                </Fragment>
            </div>
        )
    }


}

export default connector(DocumentComponent)
