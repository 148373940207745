import { set, ref, onValue, DataSnapshot } from "firebase/database";
import { db } from "./firebase"
import { Document } from "../models/document"
import { store } from "./store"
import { setDocument } from "./document-reducer"
import { Operation } from "../models/operation"
import * as OperationService from '../services/operation-service'
import { Unsubscribe } from "firebase/database";
import { setOperation, unsetOperation } from "./operation-reducer";
import { cloneDeep } from "lodash"
import { Connection } from "../models/connection";

export type DocumentCallback = (document: Document) => void

export const defaultDocument = {
  uuid: "1014969b-7554-41cd-942b-95a5890ed9f7", // TODO: make dynamic
  name: 'Untitled',
  operation_uuids: []
}

let operation_unsubs: Record<string, Unsubscribe> = {}// TODO:Probably doesn't belong in state object

function documentRef(uuid: string) {
  return ref(db, 'documents/' + uuid)
}

export function writeDocument(document: Document, completionCallback?: () => null, errorCallback?: (error: any) => null) {
  set(documentRef(document.uuid), document).then(completionCallback).catch(errorCallback);
}

export function addOperation(operation: Operation) {
  OperationService.writeOperation(operation)
  const document: Document = cloneDeep(store.getState().document)

  if (document.operation_uuids) {
    document.operation_uuids.push(operation.uuid)
  } else {
    document.operation_uuids = [operation.uuid]
  }

  writeDocument(document)
}

export function addConnection(connection: Connection) {
  const document: Document = cloneDeep(store.getState().document)
  if (!document.connections) {
    document.connections = {}
  }
  document.connections[connection.uuid] = connection
  writeDocument(document)
}

export function removeConnection(uuid:string){
  const document: Document = cloneDeep(store.getState().document)
  const connections:Record<string,Connection> | undefined = document.connections
  if(connections){
    delete connections[uuid]
  }
  document.connections = connections //TODO: This needed?
  writeDocument(document)
}

export function getDocument(uuid: string, callback?: DocumentCallback) {
  // const dispatch = useAppDispatch();
  return onValue(documentRef(uuid), (snapshot: DataSnapshot) => {
    let document = snapshot.val();
    if (document) {
      store.dispatch(setDocument(document))
      removeUnusedOperations(document)
      subscribeToOperations(document)

      if (callback) {
        callback(document)
      }
    } else {
      console.log("got null document ")
      // TODO: Perform unsubscribe functions. Probably means document has been delted so navigate back to document selector
    }
  });
}

function subscribeToOperations(document: Document) {
  operation_unsubs = document.operation_uuids?.reduce((acc: Record<string, Unsubscribe>, uuid: string) => {
    if (!Object.keys(store.getState().operations).includes(uuid)) {
      let sub = OperationService.getOperation(uuid, (operation: Operation) => {
        if (operation?.uuid) {
          store.dispatch(setOperation(operation))
        }
      })
      acc[uuid] = sub
    }
    return acc
  }, operation_unsubs ? operation_unsubs : {})
}

function removeUnusedOperations(document: Document) {
  // this.setState((previousState: State) => {
  const operations: Record<string, Operation> = store.getState().operations
  let missing_operation_uuids = Object.keys(operations)?.filter((uuid) =>
    !document.operation_uuids?.includes(uuid)
  )

  if (missing_operation_uuids?.length) {
    missing_operation_uuids?.forEach((missing_uuid) => {
      console.log("removing window", missing_uuid)
      OperationService.deleteOperation(missing_uuid) // remove window from database and render
      store.dispatch(unsetOperation(missing_uuid)) // remove local window data used for rendering
      let unsubscribe: Unsubscribe | undefined = operation_unsubs ? operation_unsubs[missing_uuid] : undefined
      if (unsubscribe) {
        console.info("Unsubing form ", operation_unsubs)
        unsubscribe()
        delete operation_unsubs[missing_uuid] // remove local window unsubscribe reference
      } else {
        console.warn("missing unsub block for window ", missing_uuid, operation_unsubs)
      }
    })
  }
}
