import { defaultOperation, Operation } from "./operation"

export const defaultNetworkRequest: NetworkRequestOperation = {
    ...defaultOperation,
    type: 'NetworkRequest', // make dynamic
    config: {
        method: "GET", // Must be set as a part of new NetworkRequests TODO: Turn into Enum?
        url: "http://",
        headers: {},
        body: "",
    },
    response: {}
}


export interface NetworkRequestConfig {
    method: string // TODO: Turn into Enum?
    url: string
    headers: Record<string, NetworkRequestHeader>
    body: string
}

export interface NetworkRequestHeader {
    uuid: string
    enabled?: boolean
    key: string,
    value?: string
    description?: string
}

export interface NetworkRequestOperation extends Operation {
    config: NetworkRequestConfig
    response: object
}

export default NetworkRequestOperation